<template>
	<form @submit.prevent="handleSubmit(!v$.$invalid)" class="p-grid">
		<Toast />
		<ConfirmDialog></ConfirmDialog>
		
		<div class="grid p-fluid stickBar">
			<div class="col-12">
				<div class="card card-w-title" id="stickBar">
					<Menubar :model="nestedMenuitems">
						<template #end>
							<div class="flex justify-content-end flex-wrap card-container green-container">
								<div class="flex align-items-center justify-content-center font-bold text-gray-500 text-xl m-2">

								</div>
								<div class="flex align-items-center justify-content-center m-2">
									<Button class="p-button-rounded p-button-danger mr-0 mb-0" v-if="$router.options.history.state.back != null" icon="pi pi-times" @click="$router.go(-1)"/>
								</div>
							</div>
						</template>
					</Menubar>
				</div>
			</div>
		</div>

		<div class="grid">
			<div class="col-12">
				<div class="card">
					<EntityHeader label="TESİS EKİPMAN" entityName="equipment"></EntityHeader>
					<div class="grid">
						<div class="col-3">
							<div class="field p-fluid">
								<label for="adi">Ad <span style="color:red">*</span></label>
								<InputText id="adi" type="text" v-model="mainData.ad" :disabled="isDisabled"/>
								<span v-if="v$.mainData.ad.$error && submitted">
									<span id="email-error" v-for="(error, index) of v$.mainData.ad.$errors" :key="index">
										<small class="p-error">{{error.$message}}</small>
									</span>
								</span>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div class="col-12">
				<div class="card">
					<h5>SERVİS RAPORLARI</h5>
					<DataTable :value="servisRaporlari"
						:rowHover="true"
						:resizableColumns="true"
						selectionMode="single"
						columnResizeMode="fit"
						scrollDirection="horizontal"
						class="p-datatable-sm"
						@row-dblclick="onDblClick($event)"
						:paginator="true"
						:rows="50"
						:rowsPerPageOptions="[5, 10, 50, 100, 250]"
						paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
						currentPageReportTemplate="{totalRecords} kaydın {first} ile {last} arası gösteriliyor"
						:scrollable="true">
						<template #empty>
							Kayıt Bulunamadı.
						</template>
						<template #loading>
							Veriler yükleniyor, lütfen bekleyiniz...
						</template>
						<Column field="baslangic" header="Başlangıç"></Column>
						<Column field="bitis" header="Bitiş"></Column>
						<Column field="servisTalepNumarasi" header="Servis Talep Numarası"></Column>
						<Column field="servisTalebiIdName" header="Servis Talebi"></Column>
						<Column field="firmaIdName" header="Firma"></Column>
						<Column field="baslik" header="Başlık"></Column>
						<Column field="tezgahIdName" header="Tezgah"></Column>
						<Column field="urunIdName" header="Ürün"></Column>
						<Column field="turu" header="Türü"></Column>
						<Column field="servisUcretlimi" header="Ücretli mi"></Column>
					</DataTable>
				</div>
			</div>
						
		</div>
	</form>
</template>

<script>
import CrmService from "../service/CrmService";
import { useVuelidate } from "@vuelidate/core";
import { helpers, required } from "@vuelidate/validators";

export default {
	setup: () => ({ v$: useVuelidate() }),
	data() {
		return {
			crmService: null,
			submitted: false,
			OBJECT_TYPE_CODE: 2,
			mainData: {},
			servisRaporlari: [],

			nestedMenuitems: [
				{
					label:'Kaydet',
					icon:'pi pi-fw pi-save',
					command: () => { this.OnSave(false); },
					visible: () => !this.isDisabled
				},
				{
					label:'Kaydet & Kapat',
					icon:'pi pi-fw pi-save',
					command: () => { this.OnSave(true); },
					visible: () => !this.isDisabled
				},
			],
		}
	},
	async created() {
		this.crmService = new CrmService();
		console.log('created');

		window.addEventListener('scroll', this.handleScroll);

		let loader = this.$loading.show({
			container: this.$refs.smsContainer
		});

        debugger;
		if (this.entityId != null) {
			try {
				debugger;
				let data = await this.crmService.getTesisEkipmanById(this.entityId);
				if (data.yetkiVarmi == false) {
					this.$router.replace({ name: 'accessdenied' });
				}

				if (data.jsonData.length > 0) {
					this.mainData = data.jsonData[0];
					this.mainData["entityId"] = this.entityId;

					this.servisRaporlari = data.servisRaporlari;
				}
			} catch (error) {
				console.log(error);
				//this.$router.push('/');
			}
			finally {
				loader.hide();
			}
		}
		else {
			loader.hide();
		}
    },
	computed: {
		entityId() {
			debugger;
			return this.$route.params.id;
		},
		profileData(){
			return this.$store.getters.getProfile;
		},
		isDisabled: function() {
			if (this.profileData) {
				if (this.profileData.moduller) {
					const filtered = this.profileData.moduller.filter(x => x.name == 'Tesis Ekipman');
					if (filtered.length > 0) {
						return filtered[0].isWrite == false;
					}
				}
			}

			return true;
		},
	},
	methods: {
		async OnSave(kapat) {
			debugger;

			this.submitted = true;
			this.v$.$touch();

			if (this.v$.$invalid) {
				this.v$.$errors.forEach(element => {
					this.$toast.add({ severity: 'error', summary: 'Eksik alanlar var.', detail: element.$message, life: 3500 });
				});
			} else {
				try {
					const response = await this.crmService.getTesisEkipmanUpdate(this.mainData);
					if (response) {
						if (response.hata == true) {
							this.$toast.add({severity:'error', summary: 'Kaydedilemedi', detail: response.hataAciklamasi, life: 5000});
						}else {
							this.$toast.add({severity:'success', summary: 'Bilgi', detail:'Başarıyla Kaydedildi', life: 3000});
							if (window.opener) {
								window.opener.window.postMessage({'tag': 'refresh'}, window.location.origin);
							}
							
							if (kapat) {
								setTimeout(() => {
									window.close();
								}, 1000);
							}
						}
					}
				} catch (error) {
					this.$toast.add({severity:'error', summary: 'Eksik alanlar var.', detail: error.message, life: 3500});
				}
			}
		},
		onDblClick(event) {
			let features = 'directories=no,menubar=no,status=no,titlebar=no,toolbar=no';

                let routeData = this.$router.resolve({ name: 'serviceappointment', params: { id: event.data.ID } });
                window.open(routeData.href, '_blank', features);
        },
		handleScroll() {
			const el = document.getElementById('stickBar');

			if (window.scrollY > 50) {
				el.style.boxShadow = '0px 2px 1px rgba(0, 0, 0, 0.09), 0px 4px 2px rgba(0, 0, 0, 0.09), 0px 8px 4px rgba(0, 0, 0, 0.09), 0px 16px 8px rgba(0, 0, 0, 0.09), 0px 32px 16px rgba(0, 0, 0, 0.09)';
			}
			else {
				el.style.boxShadow = '';
			}
		},
	},
	watch: {
		profileData(val) {
			if (val) {
				if (val.moduller) {
					const filtered = val.moduller.filter(x => x.name == 'Tesis Ekipman');
					if (filtered.length == 0) {
						this.$router.replace({ name: 'accessdenied' });
					}
				}
			}
		}
	},
	validations() {
		return {
			mainData: {
				ad: {
					required : helpers.withMessage('Ad alanı dolu olmalıdır!', required),
				},
			},
		}
	}
}
</script>

<style lang="scss" scoped>
.stickBar {
	position: sticky;
    top: 6.2rem;
    z-index: 10;
}
</style>
